import Layout from "components/layout";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useEffect, useRef, useState } from "react";
import TranscriptionTool from "components/TranscriptionTool/TranscriptionTool";
import Uploadfiles from "components/TranscriptionTool/Uploadfiles";
import ToolFaq from "components/QualityTools/ToolFaq";
import {
  ImagetoTextToolFaqData,
  TranscriptionToolFaqData,
} from "components/FAQ/config";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { navigate } from "gatsby";
import SEO from "components/seo";
import { useLocation } from "@reach/router";
import Loader from "components/loader";
import BannerSection from "components/PostTranslation/BannerSection";
import BannerImage from "assets/imagetotext_banner.png"
import { ImageToTextBannerData } from "components/PostTranslation/config";

const Index = () => {
  const [files, setFiles] = useState("");

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const newFile = query.get("new_file");

  const onFileUpload = (e) => {
    const formData = new FormData();
    const file = Array.from(e.target.files);
    setFiles(file);
    formData.append("file", e.target.files);
  };

  useEffect(() => {
    if (newFile) {
      const fileInput = document.getElementById("file");
      if (fileInput) {
        fileInput.click();
      }
    }
  }, [newFile]);
  const fileSize = (fileSizeInBytes) => {
    const fileSizeInKB = fileSizeInBytes / 1024;
    if (fileSizeInKB < 1024) {
      return `${fileSizeInKB.toFixed(2)} KB`;
    } else {
      const fileSizeInMB = fileSizeInKB / 1024;
      return `${fileSizeInMB.toFixed(2)} MB`;
    }
  };
  // const fileType = (fileName) => {
  //   if (typeof fileName !== "string") return null;
  //   return fileName.split(".").pop();
  // };
  useEffect(() => {
    const fatchfile = async () => {
      // if (!files || files?.size > 20 * 1024 * 1024) return;
 
      // const fileExtension = fileType(files?.name);
      // const allowedFileTypes = ["pdf", "png", "jpeg", "webp", "heic", "heif"];
       
      // if (!allowedFileTypes.includes(fileExtension)) {
      //   return; 
      // }
      setLoader(true);
      setError("");
      try {
        const formData = new FormData();

        files.forEach((file) => {
   
          formData.append("image[]", file); 
        });
        const response = await http().post(
          endpoints.forms.imageToText,
          formData
        );

        const newShareId = response?.meta?.share_id;
        if (newShareId === undefined && !response.success) {
          setError("Unable to generate a response. Please try again.");
          return;
        }
        navigate(`/tools/image-to-text/result?share-id=${newShareId}`);
      } catch (error) {
        console.error("Error during quality analysis:", error);
        setError(error?.message?.image[0]);
      } finally {
        setLoader(false);
      }
    };
    fatchfile();
  }, [files]);


  return (
    <Layout>
      <SEO
        title="Free Image to Text Converter - Extract Text from Images & PDFs"
        description="Extract text from images, scanned documents, and PDFs accurately with the Tomedes Image to Text Converter. Free, fast, and AI-powered."
        slug="/tools/image-to-text"
      />
      <div className="bg-[#F5F5F5]">
        <div className="w-full py-12 ">
          <ToolsBreadcrumb />
          <div className="max-w-7xl mx-auto px-4 lg:pt-[60px]  pt-12  ">
            <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-2.5">
            AI-Powered Image to Text Converter - Free & Accurate
            </h1>
            
            <div className="mt-12 flex lg:flex-row flex-col gap-4">
              <Uploadfiles
                files={files}
                setFiles={setFiles}
                setLoader={setLoader}
                loader={loader}
                fileSize={fileSize}
                setError={setError}
                onFileUpload={onFileUpload}
                uploadtext="Upload image"
                text="Supported files: pdf, png, jpeg, webp, heic, heif.<br/>
                          Maximum file upload size: 20mb"
                textconvert={true}
              />
              {/* <TranscriptionTool
                loader={loader}
                error={error}
                textconvert={true}
              /> */}
                <div className="rounded-lg  bg-white shadow-box flex-1 p-4 relative">
                              <div className="flex justify-between">
                                <span className="text-[#2B2B2B] font-bold text-base font-opensans">
                                  Transcribed text
                                </span>
                                <div className="flex gap-3 items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M6.28652 0C5.86218 0 5.45521 0.168571 5.15515 0.468629C4.85509 0.768687 4.68652 1.17565 4.68652 1.6V3.2H6.28652V1.6H15.0865V10.4H13.4865V12H15.0865C15.5109 12 15.9178 11.8314 16.2179 11.5314C16.518 11.2313 16.6865 10.8243 16.6865 10.4V1.6C16.6865 1.17565 16.518 0.768687 16.2179 0.468629C15.9178 0.168571 15.5109 0 15.0865 0H6.28652ZM2.28652 4C1.86218 4 1.45521 4.16857 1.15515 4.46863C0.855094 4.76869 0.686523 5.17565 0.686523 5.6V14.4C0.686523 14.8243 0.855094 15.2313 1.15515 15.5314C1.45521 15.8314 1.86218 16 2.28652 16H11.0865C11.5109 16 11.9178 15.8314 12.2179 15.5314C12.518 15.2313 12.6865 14.8243 12.6865 14.4V5.6C12.6865 5.17565 12.518 4.76869 12.2179 4.46863C11.9178 4.16857 11.5109 4 11.0865 4H2.28652ZM2.28652 5.6H11.0865V14.4H2.28652V5.6Z"
                                      fill="#9C9C9C"
                                    />
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="15"
                                    viewBox="0 0 17 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M2.72574 14.8571C2.18491 14.8571 1.66622 14.6422 1.2838 14.2598C0.901369 13.8774 0.686523 13.3587 0.686523 12.8179V9.90471C0.686523 9.67293 0.7786 9.45063 0.942497 9.28674C1.10639 9.12284 1.32869 9.03076 1.56047 9.03076C1.79226 9.03076 2.01455 9.12284 2.17845 9.28674C2.34235 9.45063 2.43442 9.67293 2.43442 9.90471V12.8179C2.43442 12.9787 2.56493 13.1092 2.72574 13.1092H14.961C15.0383 13.1092 15.1124 13.0785 15.167 13.0239C15.2217 12.9692 15.2523 12.8951 15.2523 12.8179V9.90471C15.2523 9.67293 15.3444 9.45063 15.5083 9.28674C15.6722 9.12284 15.8945 9.03076 16.1263 9.03076C16.3581 9.03076 16.5804 9.12284 16.7443 9.28674C16.9082 9.45063 17.0002 9.67293 17.0002 9.90471V12.8179C17.0002 13.3587 16.7854 13.8774 16.403 14.2598C16.0205 14.6422 15.5019 14.8571 14.961 14.8571H2.72574Z"
                                      fill="#9C9C9C"
                                    />
                                    <path
                                      d="M7.96919 7.50315V0.87395C7.96919 0.642164 8.06126 0.419871 8.22516 0.255974C8.38906 0.0920766 8.61135 0 8.84314 0C9.07492 0 9.29721 0.0920766 9.46111 0.255974C9.62501 0.419871 9.71709 0.642164 9.71709 0.87395V7.50315L12.0127 5.20874C12.0938 5.12764 12.19 5.0633 12.296 5.01941C12.402 4.97552 12.5156 4.95292 12.6303 4.95292C12.7449 4.95292 12.8585 4.97552 12.9645 5.01941C13.0705 5.0633 13.1667 5.12764 13.2478 5.20874C13.3289 5.28984 13.3933 5.38613 13.4372 5.49209C13.4811 5.59806 13.5037 5.71163 13.5037 5.82633C13.5037 5.94103 13.4811 6.0546 13.4372 6.16057C13.3933 6.26653 13.3289 6.36282 13.2478 6.44392L9.46073 10.231C9.37967 10.3122 9.2834 10.3766 9.17742 10.4206C9.07145 10.4645 8.95786 10.4871 8.84314 10.4871C8.72842 10.4871 8.61482 10.4645 8.50885 10.4206C8.40288 10.3766 8.30661 10.3122 8.22555 10.231L4.43843 6.44392C4.35733 6.36282 4.29299 6.26653 4.2491 6.16057C4.20521 6.0546 4.18262 5.94103 4.18262 5.82633C4.18262 5.71163 4.20521 5.59806 4.2491 5.49209C4.29299 5.38613 4.35733 5.28984 4.43843 5.20874C4.51953 5.12764 4.61582 5.0633 4.72179 5.01941C4.82775 4.97552 4.94133 4.95292 5.05602 4.95292C5.17072 4.95292 5.28429 4.97552 5.39026 5.01941C5.49623 5.0633 5.59251 5.12764 5.67361 5.20874L7.96919 7.50315Z"
                                      fill="#9C9C9C"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <hr className="border border-[#C7C7C7] my-2" />
                              <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
                                {error}
                              </span>
                              {loader && (
                                <div className="absolute top-[50%] left-[50%]">
                                  <Loader />
                                </div>
                              )}
                            </div>
            </div>
          </div>
          <p className="w-full text-center mt-2 text-base text-[#9C9C9C]  mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
         
        </div>
      </div>
      <div className="bg-white ">
          <div className="max-w-7xl mx-auto p-4">
            <BannerSection
              title="Extract Text from Images and PDFs & Compare Two AI Outputs Instantly"
              description="The Tomedes Image to Text Converter extracts text from pictures into editable and searchable text in seconds. It uses advanced AI and dual OCR technology to generate up to two OCR outputs for each file. This allows you to compare the extracted texts to ensure accuracy.
              <br /><br/>
              This versatile tool supports a wide range of formats, including PNG, JPEG, PDF, WEBP, HEIC, and more. Whether you need to convert printed documents, handwritten notes, screenshots, or complex scanned visuals into editable files, the Tomedes OCR tool streamlines your workflow effortlessly. Just upload your file(s) and instantly receive ready-to-use text you can edit, copy, or export
              "

              BannerData={ImageToTextBannerData}
              BannerImage={BannerImage}
              text="With an intuitive interface, batch upload capabilities, and AI-driven precision, the Tomedes Image to Text Converter is the go-to choice for linguists, businesses, students, and professionals worldwide. Best of all, it's 100% free and requires no registration."
             isTranslatoir={true}
           />
          </div>
        </div>
      <ToolFaq QaToolFaqData={ImagetoTextToolFaqData} />
    </Layout>
  );
};

export default Index;



// import Layout from "components/layout";
// import ToolsBreadcrumb from "components/ToolsBredcrumb";
// import React, { useEffect, useRef, useState } from "react";
// import TranscriptionTool from "components/TranscriptionTool/TranscriptionTool";
// import Uploadfiles from "components/TranscriptionTool/Uploadfiles";
// import ToolFaq from "components/QualityTools/ToolFaq";
// import {
//   ImagetoTextToolFaqData,
//   TranscriptionToolFaqData,
// } from "components/FAQ/config";
// import http from "utils/http";
// import { endpoints } from "utils/endpoints";
// import { navigate } from "gatsby";
// import SEO from "components/seo";
// import { useLocation } from "@reach/router";
// import Loader from "components/loader";
// import ImagetoTextInput from "components/TranscriptionTool/ImagetoTextInput";

// const Index = () => {
//   const [UploadFiles, setUploadFiles] = useState("");
//   const [files, setFiles] = useState("");
//   const [loader, setLoader] = useState(false);
//   const [error, setError] = useState(false);
//    const [selectedFileIndex, setSelectedFileIndex] = useState(0);
//      const [outputText, setOutputText] = useState("");
//   const location = useLocation();
//   const query = new URLSearchParams(location.search);
//   const newFile = query.get("new_file");

//   const onFileUpload = (e) => {
//     const formData = new FormData();
//     const file = Array.from(e.target.files);
//     setUploadFiles(file);
//     formData.append("file", e.target.files);
//   };

//   useEffect(() => {
//     if (newFile) {
//       const fileInput = document.getElementById("file");
//       if (fileInput) {
//         fileInput.click();
//       }
//     }
//   }, [newFile]);
//   const fileSize = (fileSizeInBytes) => {
//     const fileSizeInKB = fileSizeInBytes / 1024;
//     if (fileSizeInKB < 1024) {
//       return `${fileSizeInKB.toFixed(2)} KB`;
//     } else {
//       const fileSizeInMB = fileSizeInKB / 1024;
//       return `${fileSizeInMB.toFixed(2)} MB`;
//     }
//   };

//   useEffect(() => {
//     const fatchfile = async () => {

//       setLoader(true);
//       setError("");
//       try {
//         const formData = new FormData();

//         UploadFiles.forEach((file) => {
//           formData.append("image[]", file);
//         });
//         const response = await http().post(
//           endpoints.forms.imageToText,
//           formData
//         );

//         const newShareId = response?.meta?.share_id;
//         if (newShareId === undefined && !response.success) {
//           setError("Unable to generate a response. Please try again.");
//           return;
//         }
//         setFiles(response);
//         setOutputText(response?.result || []);
//         navigate(`/tools/image-to-text/result?share-id=${newShareId}`);
//       } catch (error) {
//         console.error("Error during quality analysis:", error);
//         setError(error?.message?.image[0]);
//       } finally {
//         setLoader(false);
//       }
//     };
//     fatchfile();
//   }, [UploadFiles]);

//  const allowedFileTypes = [
//   "pdf",
//   "png",
//   "jpeg",
//   "jpg",
//   "webp",
//   "heic",
//   "HEIC",
//   "heif",
//   "HEIF",
// ];
// const outputData = outputText[selectedFileIndex] || "";


//   return (
//     <Layout>
//       <SEO
//         title="Free Image to Text Converter"
//         description="Convert images into editable text instantly with the Tomedes Image to Text Converter. Supports PNG, JPEG, and other formats. Fast, accurate, and free.​"
//         slug="/tools/image-to-text"
//       />
//       <div>
//         <div className="w-full py-7 ">
//           <ToolsBreadcrumb />
//           <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] lg:pb-[60px] pt-12 pb-8 ">
//             <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-2.5">
//               Image to text converter
//             </h1>
//             <h2 className="text-[28px]  md:text-3xl leading-[48px]  text-center font-primary  font-bold ">
//               Stop Copying Text from Images Manually - Let AI Do It for You
//             </h2>
//             <div className="mt-12 flex lg:flex-row flex-col gap-4">
//               {!files ?(
//               <Uploadfiles
//                 files={UploadFiles}
//                 setFiles={setUploadFiles}
//                 setLoader={setLoader}
//                 loader={loader}
//                 fileSize={fileSize}
//                 setError={setError}
//                 onFileUpload={onFileUpload}
//                 uploadtext="Upload image"
//                 text="Supported files: pdf, png, jpeg, webp, heic, heif.<br/>
//                           Maximum file upload size: 20mb"
//                 textconvert={true}
//               />
//               ) : (
//                 <ImagetoTextInput
//                 files={files} 
//                 allowedFileTypes={allowedFileTypes} 
//                 outputData={outputData} 
//                 setSelectedFileIndex={setSelectedFileIndex} 
//                 setFiles={setFiles}
//                 outputText={outputText}
//                 setOutputText={setOutputText}
//                 error={error}
//                 setError={setError}
//                 setLoader={setLoader}
//                 // displayShareId={displayShareId}
//                 />
//               )}
        
//               <div className="rounded-lg  bg-white shadow-box flex-1 p-4 relative">
//                 <div className="flex justify-between">
//                   <span className="text-[#2B2B2B] font-bold text-base font-opensans">
//                     Transcribed text
//                   </span>
//                   <div className="flex gap-3 items-center">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="17"
//                       height="16"
//                       viewBox="0 0 17 16"
//                       fill="none"
//                     >
//                       <path
//                         fill-rule="evenodd"
//                         clip-rule="evenodd"
//                         d="M6.28652 0C5.86218 0 5.45521 0.168571 5.15515 0.468629C4.85509 0.768687 4.68652 1.17565 4.68652 1.6V3.2H6.28652V1.6H15.0865V10.4H13.4865V12H15.0865C15.5109 12 15.9178 11.8314 16.2179 11.5314C16.518 11.2313 16.6865 10.8243 16.6865 10.4V1.6C16.6865 1.17565 16.518 0.768687 16.2179 0.468629C15.9178 0.168571 15.5109 0 15.0865 0H6.28652ZM2.28652 4C1.86218 4 1.45521 4.16857 1.15515 4.46863C0.855094 4.76869 0.686523 5.17565 0.686523 5.6V14.4C0.686523 14.8243 0.855094 15.2313 1.15515 15.5314C1.45521 15.8314 1.86218 16 2.28652 16H11.0865C11.5109 16 11.9178 15.8314 12.2179 15.5314C12.518 15.2313 12.6865 14.8243 12.6865 14.4V5.6C12.6865 5.17565 12.518 4.76869 12.2179 4.46863C11.9178 4.16857 11.5109 4 11.0865 4H2.28652ZM2.28652 5.6H11.0865V14.4H2.28652V5.6Z"
//                         fill="#9C9C9C"
//                       />
//                     </svg>
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="17"
//                       height="15"
//                       viewBox="0 0 17 15"
//                       fill="none"
//                     >
//                       <path
//                         d="M2.72574 14.8571C2.18491 14.8571 1.66622 14.6422 1.2838 14.2598C0.901369 13.8774 0.686523 13.3587 0.686523 12.8179V9.90471C0.686523 9.67293 0.7786 9.45063 0.942497 9.28674C1.10639 9.12284 1.32869 9.03076 1.56047 9.03076C1.79226 9.03076 2.01455 9.12284 2.17845 9.28674C2.34235 9.45063 2.43442 9.67293 2.43442 9.90471V12.8179C2.43442 12.9787 2.56493 13.1092 2.72574 13.1092H14.961C15.0383 13.1092 15.1124 13.0785 15.167 13.0239C15.2217 12.9692 15.2523 12.8951 15.2523 12.8179V9.90471C15.2523 9.67293 15.3444 9.45063 15.5083 9.28674C15.6722 9.12284 15.8945 9.03076 16.1263 9.03076C16.3581 9.03076 16.5804 9.12284 16.7443 9.28674C16.9082 9.45063 17.0002 9.67293 17.0002 9.90471V12.8179C17.0002 13.3587 16.7854 13.8774 16.403 14.2598C16.0205 14.6422 15.5019 14.8571 14.961 14.8571H2.72574Z"
//                         fill="#9C9C9C"
//                       />
//                       <path
//                         d="M7.96919 7.50315V0.87395C7.96919 0.642164 8.06126 0.419871 8.22516 0.255974C8.38906 0.0920766 8.61135 0 8.84314 0C9.07492 0 9.29721 0.0920766 9.46111 0.255974C9.62501 0.419871 9.71709 0.642164 9.71709 0.87395V7.50315L12.0127 5.20874C12.0938 5.12764 12.19 5.0633 12.296 5.01941C12.402 4.97552 12.5156 4.95292 12.6303 4.95292C12.7449 4.95292 12.8585 4.97552 12.9645 5.01941C13.0705 5.0633 13.1667 5.12764 13.2478 5.20874C13.3289 5.28984 13.3933 5.38613 13.4372 5.49209C13.4811 5.59806 13.5037 5.71163 13.5037 5.82633C13.5037 5.94103 13.4811 6.0546 13.4372 6.16057C13.3933 6.26653 13.3289 6.36282 13.2478 6.44392L9.46073 10.231C9.37967 10.3122 9.2834 10.3766 9.17742 10.4206C9.07145 10.4645 8.95786 10.4871 8.84314 10.4871C8.72842 10.4871 8.61482 10.4645 8.50885 10.4206C8.40288 10.3766 8.30661 10.3122 8.22555 10.231L4.43843 6.44392C4.35733 6.36282 4.29299 6.26653 4.2491 6.16057C4.20521 6.0546 4.18262 5.94103 4.18262 5.82633C4.18262 5.71163 4.20521 5.59806 4.2491 5.49209C4.29299 5.38613 4.35733 5.28984 4.43843 5.20874C4.51953 5.12764 4.61582 5.0633 4.72179 5.01941C4.82775 4.97552 4.94133 4.95292 5.05602 4.95292C5.17072 4.95292 5.28429 4.97552 5.39026 5.01941C5.49623 5.0633 5.59251 5.12764 5.67361 5.20874L7.96919 7.50315Z"
//                         fill="#9C9C9C"
//                       />
//                     </svg>
//                   </div>
//                 </div>
//                 <hr className="border border-[#C7C7C7] my-2" />
//                 <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
//                   {error}
//                 </span>
//                 {loader && (
//                   <div className="absolute top-[50%] left-[50%]">
//                     <Loader />
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//           <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
//             Assisted by AI tools. Inaccuracies may occur.
//           </p>
//           <p className="mt-8 max-w-5xl mx-auto md:mt-12 text-lg text-center font-opensans">
//             This Image to Text Converter transforms static images into editable,
//             searchable text with just a click. Upload your PNG, JPEG, WEBP, HEIC
//             or HEIF files, and let AI do the rest. Ideal for extracting notes,
//             digitizing printed documents, or capturing text from visuals, this
//             tool helps you save time, reduce manual effort, and focus on what
//             matters most.
//           </p>
//         </div>
//       </div>
//       <ToolFaq QaToolFaqData={ImagetoTextToolFaqData} />
//     </Layout>
//   );
// };

// export default Index;
